<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MonitorIcon,
  HeartIcon,
  EyeIcon,
  BoldIcon,
  FeatherIcon,
  CodeIcon,
  UserCheckIcon,
  GitMergeIcon,
  SettingsIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar";
import Features from "@/components/features";
import Footer from "@/components/footer";


/**
 * Page-aboutus component
 */
export default {
  data() {
    return {
      featuresData: [
        {
          icon: "uil uil-airplay",
          title: "Investor",
          description:
            "DETONG CAPITAL",
        },
        {
          icon: "uil uil-calendar-alt",
          title: "Our Mission",
          description:
            "Bring the best easy-to-use technology to boost the growth of booking and improve the quality of service, while decreasing the cost of the client's operation.",
        },
        {
          icon: "uil uil-clock",
          title: "Our Vission",
          description:
            "The best travel business technology company for the ttd industry in Asian Pacific area.",
        },
      ],
    };
  },
  components: {
    Navbar,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MonitorIcon,
    HeartIcon,
    EyeIcon,
    BoldIcon,
    FeatherIcon,
    CodeIcon,
    UserCheckIcon,
    GitMergeIcon,
    SettingsIcon,
    Features,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">About us</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">TREEP</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <section class="section">
        <div class="container">
            <div class="row align-items-center" id="counter">
                <div class="col-md-6">
                    <img src="images/template/about2.png" class="img-fluid" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div class="ml-lg-4">
                        <div class="d-flex mb-4">
                            <span class="text-primary h1 mb-0"><span class="counter-value display-1 font-weight-bold" data-count="15">7</span>+</span>
                            <span class="h6 align-self-end ml-2">Years <br> Experience</span>
                        </div>
                        <div class="section-title">
                            <h4 class="title mb-4">Who we are ?</h4>
                            <p class="text-muted">
                              <span class="text-primary font-weight-bold">TREEP</span>, founded in 2017 and invested by DT Capital, an official system partner for the supply chain of TRIP.COM, Klook, Fliggy etc,is a leading brandd of business management system, covering activities, tickets and tour operators in the travel industry through south east Asia. treep serves over 11,000 agencies and hundreds of suppliers throughout Thailand and the rest of Asia Pacific area, with 200 million dollars per year of booking transactions.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
        <div class="container mt-5">
        <Features :featuresData="featuresData" />
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Team Start -->
    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="title mb-4">
                See what value we can bring to you
              </h4>
              <p class="text-muted para-desc mx-auto">
                A chat with our industry experts can lead to new growth ideas for your business.
              </p>

              <div class="mt-4">
                <a href="https://treep.cc/demo/?channelType=1" class="btn btn-primary mt-2 mr-2" target="_blank"
                  >Contact us now</a
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Team End -->

    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
